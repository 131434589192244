import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '@ptg-shared/controls/select/select.component';

@Component({
  selector: 'ptg-select-add-chip',
  templateUrl: './select-add-chip.component.html',
  styleUrls: ['./select-add-chip.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAddChipComponent),
      multi: true,
    },
  ],
})
export class SelectAddChipComponent implements ControlValueAccessor {
  _renderOptions: Option[] = [];
  _options: Option[] = [];
  get options(): Option[] {
    return this._options;
  }
  @Input() set options(options: Option[]) {
    this._options = options.map((option, index) => ({
      ...option,
      order: index
    }));
    this._renderOptions = options;
    this.updateRenderOptions();
  }
  @Input() placeholder!: string;
  @Input() getDisplayValueForItem!: (value: any, options: Option[]) => string;
  @Input() width?: string;
  @Input() keepChipOrder = false;
  control = new FormControl();
  selectedOptions: Option[] = [];
  disabled?: boolean;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any[]): void {
    this.selectedOptions = value || [];
    if (this.keepChipOrder) {
      this.selectedOptions = this.selectedOptions.map((option) => {
        option.order = this._options.find((_option) => _option.value === option.value)?.order;
        return option;
      }) || [];
    }
    this.updateRenderOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  // Handle the blur event
  handleBlur(): void {
    this.onTouched();
  }

  addValue() {
    const control = this.control;
    if (control.value === null) {
      control.markAsTouched();
      return;
    }
    this.selectedOptions.push({
      value: control.value,
      displayValue: this.getDisplayValueForItem(control.value, this._options),
      order: this._options.find(_option => _option.value === control.value)?.order,
    });
    if (this.keepChipOrder) {
      this.selectedOptions.sort((option1, option2) => (option1.order ?? 0) - (option2.order ?? 0));
    }
    this.updateRenderOptions();
    control.setValue(null);
    this.onChange(this.selectedOptions);
    control.markAsUntouched();
  }

  removeValue(option: Option) {
    this.selectedOptions = this.selectedOptions.filter((selected) => {
      return selected.value !== option.value;
    });
    this.updateRenderOptions();
    this.onChange(this.selectedOptions);
  }

  updateRenderOptions() {
    this._renderOptions = this._options.filter(({ value }) => {
      return this.selectedOptions.every((selected) => selected.value !== value);
    });
  }
}
