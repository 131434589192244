import { RadioOption } from "@ptg-shared/controls/radio-button/radio-button.component";

export const CONTROL_NAME_QDRO = 'qdro';
export const CREATE_OFFSET_DEDUCTION_LABEL = 'Create Offset Deduction';

export const TAXABILITY_OPTIONS: RadioOption[] = [
  {
    label: 'Pre Tax',
    value: true,
  },
  {
    label: 'Post Tax',
    value: false,
  },
];
